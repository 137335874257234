import * as React from 'react';
import { Settings } from 'react-slick';
import { graphql } from 'gatsby';
import { HomeReviews_Data } from 'generated/graphql';
import { formatDay, getNumWordWithCount } from 'utils';
import StarFilled from './icons/StarFilled';
import StarOutlined from './icons/StarOutlined';
import { HomeCarousel } from './HomeCarousel';
import { Image } from './Image';

type Props = {
  data: readonly HomeReviews_Data[];
};

const RatingStatus = ({ rating }: { rating: number }) => (
  <div className="w-[95px] flex relative flex-shrink-0 h-6">
    {[...Array(5).keys()].map((i) => {
      if (rating > i) {
        return <StarFilled key={i} style={{ left: i * 17.5, zIndex: i + 10 }} className="flex-shrink-0 absolute" />;
      }
      return (
        <StarOutlined
          key={i}
          style={{ left: i * 17.5, zIndex: i + 10 }}
          className="flex-shrink-0 absolute opacity-40"
        />
      );
    })}
  </div>
);

const sliderSettings: Settings = {
  slidesToShow: 3,
  adaptiveHeight: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const HomeReviews = ({ data }: Props) => (
  <div className="pt-8 md:pt-6">
    <HomeCarousel homeTitle={{ title: 'Отзывы о продуктах' }} settings={sliderSettings}>
      {data.map((review) => (
        <div key={review.id} className="p-2 w-1/3 xl:w-1/2 sm:w-full">
          <div key={review.id} className="p-5 bg-[#f4f4f4] ">
            <div style={{ borderColor: 'rgba(168, 168, 168, 0.4)' }} className="flex pb-5 border-b xs:flex-col xs:pb-0">
              <Image className="mr-5 w-[124px] h-[124px] rounded-xl xs:mb-3" src={review.product.mainImageUrl} alt="" />
              <div className="flex flex-col h-32">
                <div className="flex items-center text-sm">
                  {/* <StarFilled className="mr-1" /> */}
                  {/* <span className="mr-2 text-orange-50">{review.product.rating}</span> */}
                  <span className="text-[#666]">
                    {getNumWordWithCount(review.product.reviewCount, ['отзыв', 'отзыва', 'отзывов'])}
                  </span>
                </div>
                <div className="text-lg xs:text-sm xs:mb-3 h-[46px] overflow-hidden overflow-ellipsis mb-auto leading-tight">
                  {review.product.name}
                </div>
                {review.product.oldPrice ? (
                  <div className="text-[#666] mt-auto line-through">{review.product.oldPrice} ₽</div>
                ) : null}
                <div className="text-[22px] text-orange-50 font-medium">{review.product.price} ₽</div>
              </div>
            </div>
            <div className="mt-5 h-36">
              <div className="flex xs:flex-wrap m">
                <span className="font-medium text-[#272727] mr-2 whitespace-nowrap overflow-hidden overflow-ellipsis flex-shrink-0">
                  {review.authorName}
                </span>
                <div className="flex flex-wrap w-full">
                  <RatingStatus rating={review.rating} />
                  <span className="ml-auto text-[#666]">{formatDay(review.createdAt)}</span>
                </div>
              </div>
              <div className="py-1 h-[70px] overflow-hidden text-[#666] md:text-sm md:h-[65px]">{review.body}</div>
              <a
                href={review.product.pageUrl}
                className="inline-block mt-3 mb-1 text-orange-50 font-medium hover:text-[#FA733A] transition-colors"
              >
                Читать все отзывы
              </a>
            </div>
          </div>
        </div>
      ))}
    </HomeCarousel>
  </div>
);

export const gql = graphql`
  fragment HomeReviews_data on ProductReview {
    id
    authorName
    rating
    body
    createdAt
    product {
      name
      oldPrice
      price
      reviewCount
      pageUrl
      mainImageUrl(size: 124)
    }
  }
`;

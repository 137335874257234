import * as React from 'react';
import CartWhiteIcon from './icons/CartWhite';

const badgeClassName =
  'absolute rounded-full w-7 h-7 flex justify-center items-center text-black bg-white text-sm font-medium top-[-26px] right-[-36px] xs:right-[-22px]';

type Props = {
  quantity: number;
};

export const BannerCartIcon = ({ quantity }: Props) => (
  <span className="block relative">
    {quantity > 0 && <span className={badgeClassName}>{quantity}</span>}
    <CartWhiteIcon size={24} />
  </span>
);

import * as React from 'react';
import { graphql } from 'gatsby';
import { IndexPageQuery } from 'generated/graphql';
import { HomeHeader } from 'components/HomeHeader';
import { Chat } from 'components/Chat';
import { HomeBanners } from 'components/HomeBanners';
import { HomeProductLists } from 'components/HomeProductLists';
import { HomeCategoryList } from 'components/HomeCategoryList';
import { HomeFirstOrder } from 'components/HomeFirstOrder';
import { HomeDoubleBanner } from 'components/HomeDoubleBanner';
import { HomeReviews } from 'components/HomeReviews';
import { HomeFooter } from 'components/HomeFooter';
import { ModalLogic } from 'components/ModalLogic';
import { LastOrder } from 'components/LastOrder';

import { useAnalyticsScroll } from 'analytics';
import { HeadMetaData } from 'components/HeadMetaData';

type Props = {
  data: IndexPageQuery;
};

const IndexPage = ({ data }: Props) => {
  useAnalyticsScroll();

  return (
    <>
      <HeadMetaData title="Familyfriend | Быстрая доставка свежих и вкусных продуктов" />
      <HomeHeader />
      <div className="container px-2">
        {(process.env.NODE_ENV !== 'production' && <LastOrder />) ||
          (data.viewer.features.showLastOrder && <LastOrder />)}
        <HomeBanners data={data} />
        <HomeProductLists data={data} />
        <HomeCategoryList data={data} />
        <HomeFirstOrder />
        <HomeDoubleBanner />
        <HomeReviews data={data.lastProductReviews} />
      </div>
      <HomeFooter />
      <ModalLogic />
      <Chat />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  fragment IndexPage_data on Query {
    ...HomeBanners_data
    ...HomeCategoryList_data
    ...HomeProductLists_data
    lastProductReviews {
      ...HomeReviews_data
    }
    viewer {
      features {
        showLastOrder
      }
    }
  }

  query IndexPageQuery {
    ...IndexPage_data
  }
`;

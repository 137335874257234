import * as React from 'react';
import cn from 'classnames';
import { Banners_Data } from 'generated/graphql';
import { graphql, Link } from 'gatsby';
import { clickBanner } from 'analytics';
import { useStoreon } from 'hooks';
import { BannerProductButtons } from './BannerProductButtons';

type Props = {
  readonly data: Banners_Data;
  readonly className?: string;
};

type OptionalLinkProps = {
  readonly to: string;
  readonly children: React.ReactNode;
};

const getUrl = ({ product, category, url }: Banners_Data) => {
  if (url) {
    return url;
  }
  if (product) {
    return product.pageUrl;
  }
  if (category) {
    return category.pageUrl;
  }
  return '';
};

const OptionalLink = ({ to, children }: OptionalLinkProps) => {
  if (to) {
    return (
      <Link onClick={clickBanner} to={to}>
        {children}
      </Link>
    );
  }

  return <div>{children}</div>;
};

export const Banner = ({ data, className }: Props) => {
  const { dispatch } = useStoreon();

  const increase = () => {
    if (!data.product) return;

    dispatch('increaseCartItemQuantity', data.product);
  };

  return (
    <div className={cn("relative max-h-[590px] h-[0] pb-[40%] 2xl:pb-[48%] xl:pb-[53%]", className)}>
      <OptionalLink to={getUrl(data)}>
        <picture>
          <source srcSet={`${data.imageUrlBig1x} 1x, ${data.imageUrlBig2x} 2x`} media="(min-width: 1550px)" />
          <source
            srcSet={`${data.imageUrlMiddle1x} 1x, ${data.imageUrlMiddle2x} 2x`}
            media="(min-width: 1380px) and (max-width: 1549px)"
          />
          <source
            srcSet={`${data.imageUrlSmall1x} 1x, ${data.imageUrlSmall2x} 2x`}
            media="(min-width: 520px) and (max-width: 1379px)"
          />
          <img
            src={data.imageUrlVerySmall2x}
            srcSet={`${data.imageUrlVerySmall1x} 1x, ${data.imageUrlVerySmall2x} 2x`}
            className="absolute h-full object-cover rounded-[12px] xs:rounded-[6px] w-full block"
            alt="banner"
          />
        </picture>
      </OptionalLink>
      <div>
        <div className="absolute bottom-[48px] left-[48px] xs:bottom-[16px] xs:left-[22px] xs:w-[95%] sm:bottom-[16px]">
          <div className="mb-[32px] xs:mb-[-35px] text-white">
            {data.label && (
              <div className="flex flex-initial mb-3 md:hidden">
                <p className="flex-initial px-5 py-2 text-md border-2 border-[#ffffff5e] rounded-full lg:text-sm">
                  {data.label}
                </p>
              </div>
            )}
            {data.title && (
              <div className={`${!data.product?.price ? 'xs:mb-[34px]' : null} "flex flex-initial mb-[12px]"`}>
                <p className="flex-initial text-[36px] xs:text-[16px] font-semibold max-w-[300px]">{data.title}</p>
              </div>
            )}
            {data.description && (
              <div className="flex flex-initial xs:hidden">
                <p className="flex-initial text-[14px] font-normal rounded-full max-w-[422px]">{data.description}</p>
              </div>
            )}
          </div>
          {data.product && <BannerProductButtons product={data.product} increase={increase} />}
        </div>
      </div>
    </div>
  );
};

export const fragments = graphql`
  fragment Banners_data on Banner {
    id
    url
    label
    description
    title
    imageUrlVerySmall1x: appImageUrl(size: 363)
    imageUrlVerySmall2x: appImageUrl(size: 726)
    imageUrlSmall1x: siteImageUrl(size: 414)
    imageUrlSmall2x: siteImageUrl(size: 828)
    imageUrlMiddle1x: siteImageUrl(size: 1246)
    imageUrlMiddle2x: siteImageUrl(size: 2492)
    imageUrlBig1x: siteImageUrl(size: 2492)
    imageUrlBig2x: siteImageUrl(size: 2492)
    promoCode
    category {
      id
      pageUrl
    }
    product {
      ...ProductCounter_product
      pageUrl
    }
  }
`;

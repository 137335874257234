/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';

import { AttachedImage } from 'hooks';
import Close from './icons/Close';

type Props = {
  readonly images: AttachedImage[];
  readonly onRemove: (id: number) => void;
};

export const AttachedImages = ({ images, onRemove }: Props) => (
  <div className='flex flex-row max-w-[350px] flex-wrap'>
    {images.map((i) => {
      const { id, url } = i;

      return (
        <div key={id} className='mr-3 mb-3 relative'>
          <a href={url} target='_blank' rel="noreferrer">
            <div style={{ background: `url(${url}) 50% 50% no-repeat`, backgroundSize: 'cover' }} className='w-[40px] h-[40px] shadow' />
          </a>
          <button className='top-[-10px] right-[-10px] absolute bg-white p-1 rounded-full shadow' type='button' onClick={() => onRemove(id)}><Close size={12} /></button>
        </div>
      )
    })}
  </div>
);
import * as React from 'react';
import { graphql } from 'gatsby';
import { formatQuantity } from 'utils';
import { useCartItemQuantity } from 'hooks';
import { BannerCartIcon } from './BannerCartIcon';
import { BannerProductButtons_Data } from '../generated/graphql';

type BannerButtonsProps = {
  readonly product: BannerProductButtons_Data;
  readonly increase: () => void;
};

export const BannerProductButtons = ({ product, increase }: BannerButtonsProps) => {
  const [quantity] = useCartItemQuantity(product.id);

  return (
    <div className="flex items-center xs:items-end xs:justify-between">
      <div className="flex items-center xs:order-2">
        <button
          type="button"
          onClick={increase}
          className="flex items-center justify-center bg-orange-50 px-[23px] py-[12px] xs:px-[12px] xs:py-[14px] cursor-pointer mr-4 duration-200 rounded-md xs:rounded hover:bg-[#FA733A] active:bg-[#E56229]"
          title="Добавить в корзину"
        >
          <span className="font-medium mr-[11px] text-white xs:hidden">Добавить в корзину</span>
          {product.pageUrl && <BannerCartIcon quantity={quantity} />}
        </button>
      </div>

      {product.price && (
        <div className="flex justify-center items-center px-[16px] py-[12px] xs:py-0 xs:px-0 xs:text-[14px] bg-white xs:bg-transparent rounded-md text-center duration-200">
          <span className="flex items-baseline justify-center text-orange-50 xs:text-white font-semibold mr-1">
            {product.price.toFixed(2)} <span className="ml-1.5">₽</span>
          </span>
          <span className="font-medium text-[#676667] xs:text-white">
            за {formatQuantity(product.priceUnit, product.weight, product.volume)}
          </span>
        </div>
      )}
    </div>
  );
};

export const fragments = graphql`
  fragment BannerProductButtons_data on Product {
    id
    price
    pageUrl
    priceUnit
    weight
    volume
  }
`;

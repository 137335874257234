import * as React from 'react';
import { graphql, Link, navigate } from 'gatsby';
import classNames from 'classnames';
import qs from 'query-string';
import { MyOrderListItem_Data, OrderStatus } from 'generated/graphql';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { formatDay } from 'utils';
import { OrderFeedback } from './OrderFeedback';
import { Image } from './Image';

type Props = {
  readonly showStatusWidget?: boolean;
  readonly data: MyOrderListItem_Data;
  readonly className?: string;
};

const labels: { [key in string]: string } = {
  '0': 'сегодня',
  '1': 'завтра',
  '2': 'послезавтра',
};

const getStatus = (status: OrderStatus): { name: string; color: string } => {
  if (status === OrderStatus.Pending)
    return {
      name: 'Ожидает сборки',
      color: '#FFC236',
    };
  if (status === OrderStatus.Packing)
    return {
      name: 'Собирается',
      color: '#82D1F1',
    };
  if (status === OrderStatus.Shipped)
    return {
      name: 'Отправлен',
      color: '#4D9FEB',
    };
  if (status === OrderStatus.Completed)
    return {
      name: 'Завершен',
      color: '#bfe3b4',
    };
  if (status === OrderStatus.Canceled)
    return {
      name: 'Отменен',
      color: '#fa5813',
    };
  return { name: status, color: '' };
};

export const MyOrderListItem = ({ data, showStatusWidget, className }: Props) => {
  const o = data;
  const diff = differenceInCalendarDays(new Date(o.deliveryAt), new Date());
  const label = labels[diff] || formatDay(o.deliveryAt);
  const date = new Date(o.deliveryAt);
  const h = date.getHours().toString();
  const m = date.getMinutes().toString();
  const productsIds = o.items.map((i) => i.product.id);
  const qq = qs.stringify({ ids: productsIds, title: `Продукты из заказа № ${o.id}` }, { arrayFormat: 'comma' });

  const detailsExists = o.status !== 'PENDING' && o.status !== 'PACKING';

  const toDetails = () => navigate(`/my/order-details?id=${o.id}`);
  const status = getStatus(o.status);

  const showOrderFeedback = showStatusWidget && o.status === 'COMPLETED';

  return (
    <div
      className={classNames(
        className,
        showOrderFeedback && 'flex col-span-2 sm:col-span-1 sm:flex-col',
        'p-5 bg-[#fafafa] rounded-[20px] xs:px-3 xs:py-4',
      )}
    >
      <div className="flex-1">
        <div className="mb-3 flex justify-between text-lg font-semibold xs:mb-3 xs:text-base">
          <div>№{o.id}</div>
          <div>{o.totalSum.toLocaleString('ru-RU')} ₽</div>
        </div>
        <div className="mb-5 flex justify-between xs:mb-4 xs:text-sm">
          <div>
            <span className="">{label}</span> к {h.padStart(2, '0')}:{m.padStart(2, '0')}
          </div>

          <div className="flex items-center">
            <span
              style={{ backgroundColor: status.color }}
              className="mr-[7px] h-[9px] w-[9px] relative top-[-1px] rounded-full"
            />
            <span>{status.name}</span>
          </div>
        </div>

        <div className="mb-4 flex xs:mb-3">
          {o.items.slice(0, 3).map(({ id, product }) => (
            <Link
              key={id}
              to={product.pageUrl}
              title={product.name}
              className="h-[52px] w-[52px] rounded-[5px] mr-1.5 bg-gray-400  overflow-hidden hover:scale-105 lg:hover:scale-[1] transition-all duration-300 xs:mr-1"
            >
              <Image src={product.mainImageUrl} alt="" />
            </Link>
          ))}

          {o.items.length > 3 && (
            <Link
              to={`/my/products?${qq}`}
              title="Смотреть все"
              className="h-[52px] w-[52px] rounded-[5px] bg-[#C4C4C4] overflow-hidden mr-2 hover:scale-[1.1] lg:hover:scale-[1] transition-all duration-300 relative"
            >
              {o.items[3] ? <Image src={o.items[3].product.mainImageUrl} className="bg-gray-400" /> : null}
              <span
                style={{ background: 'linear-gradient(0deg, rgba(88, 88, 88, 0.8), rgba(88, 88, 88, 0.8))' }}
                className="absolute top-0 left-0 bottom-0 right-0 black text-white flex items-center justify-center font-bold"
              >
                + {o.items.length - 3}
              </span>
            </Link>
          )}
        </div>
        <button
          onClick={toDetails}
          className="mb-3 px-10 py-2.5 text-sm font-medium rounded-[5px] bg-orange-100 text-orange-50 hover:bg-[#ffdfd6] transition-colors disabled:bg-gray-100 disabled:text-gray-300 disabled:cursor-default"
          type="button"
          disabled={!detailsExists}
        >
          Чек
        </button>
        {!detailsExists && <div className="text-sm">* Чек будет доступен после сборки заказа</div>}
      </div>
      {showOrderFeedback && (
        <div className="flex-1 pl-5">
          <OrderFeedback data={o} />
        </div>
      )}
    </div>
  );
};

export const query = graphql`
  fragment MyOrderListItem_data on Order {
    id
    itemSum
    deliveryCost
    totalSum
    deliveryAt
    status
    ...OrderFeedback_data
    items {
      id
      product {
        id
        name
        pageUrl
        mainImageUrl(size: 115)
      }
    }
  }
`;

import * as React from 'react';
import { gql } from 'urql';
import { useLastOrderQuery } from 'generated/graphql';
import { MyOrderListItem } from './MyOrderListItem';

export const LastOrder = () => {
  const [res] = useLastOrderQuery({
    requestPolicy: 'network-only',
  });

  const data = res.data?.viewer?.user?.lastOrder;

  if (!data) {
    return null;
  }

  return (
    <div className="mt-8 sm:mt-6 sm:mb-4 ">
      <h3 className="text-4xl mb-4 py-0.5 md:text-3xl sm:text-2xl">Последний заказ</h3>
      <MyOrderListItem data={data} showStatusWidget className="flex-col" />
    </div>
  );
};

gql`
  query LastOrderQuery {
    viewer {
      id
      user {
        id
        lastOrder {
          id
          status
          ...MyOrderListItem_data
        }
      }
    }
  }
`;

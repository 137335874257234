import { graphql } from 'gatsby';
import { HomeProductLists_Data } from 'generated/graphql';
import * as React from 'react';
import { Settings } from 'react-slick';
import { HomeCarousel } from './HomeCarousel';
import { ProductCard } from './ProductCard';

import './homeProductLists.css';

type Props = {
  data: HomeProductLists_Data;
};

const sliderSettings: Settings = {
  slidesToShow: 5,
  className: 'product-list__slider',
  adaptiveHeight: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 1540,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        centerMode: true,
      },
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 330,
      settings: {
        slidesToShow: 1,
        centerMode: true,
      },
    },
    {
      breakpoint: 270,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const HomeProductLists = ({ data }: Props) => (
  <div>
    {data.hitProducts.length > 0 && (
      <div className="hidden lg:block py-8 md:py-6 -mx-2">
        <HomeCarousel settings={sliderSettings} homeTitle={{ title: 'Товары дня' }}>
          {data.hitProducts.map((i) => (
            <div key={i.id} className="px-2 !flex justify-center">
              <ProductCard data={i} key={i.id} />
            </div>
          ))}
        </HomeCarousel>
      </div>
    )}
    {data.homeCategories.map((c) => (
      <div key={c.id} className="py-8 md:py-6 -mx-2">
        <HomeCarousel
          settings={sliderSettings}
          homeTitle={{
            title: c.name,
            link: {
              title: (
                <>
                  <span className="inline sm:hidden">Смотреть все {c.name}</span>
                  <span className="hidden sm:inline">Все {c.name}</span>
                </>
              ),
              pathTo: c.pageUrl,
            },
          }}
        >
          {c.products.map((i) => (
            <div key={i.id} className="px-2 !flex justify-center">
              <ProductCard data={i} key={i.id} currentCategoryId={c.id} />
            </div>
          ))}
        </HomeCarousel>
      </div>
    ))}
  </div>
);

export const query = graphql`
  fragment HomeProductLists_data on Query {
    hitProducts {
      ...ProductCard_product
    }

    homeCategories {
      id
      name
      pageUrl
      iconUrl(size: 64)
      products {
        ...ProductCard_product
      }
    }
  }
`;

import * as React from 'react';
import cn from 'classnames';

type Props = {
  onChange: (val: string) => void;
  errorMessage?: string;
  className?: string;
  rootClassName?: string;
  placeholder?: string;
  errorMessageClassName?: string;
};

const prefixNumber = (str: string) => {
  if (str === '7') {
    return '7 (';
  }
  if (str === '8') {
    return '8 (';
  }
  if (str === '9') {
    return '7 (9';
  }
  return '7 (';
};

const addPhoneMask = (val: string) => {
  const value = val.replace(/\D+/g, '');
  const numberLength = 11;

  let result;
  if (val.includes('+8') || val[0] === '8') {
    result = '';
  } else {
    result = '+';
  }

  for (let i = 0; i < value.length && i < numberLength; i += 1) {
    switch (i) {
      case 0:
        result += prefixNumber(value[i]);
        // eslint-disable-next-line no-continue
        continue;
      case 4:
        result += ') ';
        break;
      case 7:
        result += '-';
        break;
      case 9:
        result += '-';
        break;
      default:
        break;
    }
    result += value[i];
  }

  return result;
};

export const PhoneInputWithMask = ({
  onChange,
  errorMessage,
  placeholder,
  className,
  rootClassName,
  errorMessageClassName,
}: Props) => {
  const [phone, setPhone] = React.useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return setPhone(e.target.value);
    return setPhone(addPhoneMask(e.target.value));
  };

  React.useEffect(() => {
    const unmaskedPhone = phone.replace(/[()']/g, '').replace(/\s/g, '').replace(/[-]/g, '').replace(/^\+/g, '');
    onChange(unmaskedPhone);
  }, [phone, onChange]);

  return (
    <div className={cn(rootClassName, 'relative')}>
      <input
        value={phone}
        onChange={handleChange}
        type="tel"
        placeholder={placeholder}
        className={cn(className, 'placeholder-[#666] rounded-[4px]')}
      />
      {errorMessage && <div className={cn(errorMessageClassName, 'absolute top-full text-sm')}>{errorMessage}</div>}
    </div>
  );
};

import React from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { HomeTitle, HomeTitleProps } from './HomeTitle';

type Props = {
  settings?: Settings;
  homeTitle: Pick<HomeTitleProps, 'link' | 'title'>;
};

const defaultSettings: Settings = {
  slidesToShow: 1,
  arrows: false,
  swipeToSlide: true,
};

export const HomeCarousel: React.FC<Props> = ({ settings = {}, homeTitle, children }) => {
  const [slider, setSlider] = React.useState<Slider | null>(null);

  const next = () => {
    if (slider) slider.slickNext();
  };

  const prev = () => {
    if (slider) slider.slickPrev();
  };

  const s = {
    ...defaultSettings,
    ...settings,
  };

  return (
    <div className="flex flex-col w-full">
      <HomeTitle {...homeTitle} sliderBtns={{ prev, next }} />
      <Slider ref={(c) => setSlider(c)} {...s}>
        {children}
      </Slider>
    </div>
  );
};

import * as React from 'react';
import { Resolver, useForm, Controller, FieldError, FieldErrors, Control } from 'react-hook-form';
import cn from 'classnames';
import { normalizePhone, validateEmail } from 'utils';
import GiftImage from 'images/home-gift-image.png';
import { gql } from 'urql';
import { useHomeFirstOrderMutation } from 'generated/graphql';
import { useStoreon } from 'hooks';
import DotLoader from './icons/DotLoader';
import { PhoneInputWithMask } from './PhoneInputWithMask';

type FormValues = {
  phone: string;
  email: string;
};

type Validator = (values: FormValues) => ErrorData | null;

type ErrorData = {
  field: keyof FormValues;
  error: FieldError;
};

const validators: Validator[] = [
  (values) => {
    if (normalizePhone(values.phone).length !== 11) {
      return {
        field: 'phone',
        error: {
          type: 'required',
          message: 'Некорректный номер',
        },
      };
    }
    return null;
  },
  (values) => {
    if (!validateEmail(values.email)) {
      return {
        field: 'email',
        error: {
          type: 'required',
          message: 'Некорректный email',
        },
      };
    }
    return null;
  },
];

const resolver: Resolver<FormValues> = async (values) => {
  const errorData: ErrorData[] = [];
  validators.forEach((v) => {
    const ed = v(values);
    if (ed) {
      errorData.push(ed);
    }
  });

  if (errorData.length > 0) {
    const errors: FieldErrors<FormValues> = {};
    errorData.forEach((e) => {
      errors[e.field] = e.error;
    });
    return { errors, values: {} };
  }

  return { values, errors: {} };
};

type FormProps = {
  promoCode: string | null;
  control: Control<FormValues>;
  errors: FieldErrors<FormValues>;
  onSubmit: () => void;
  loading?: boolean;
};

const Form = ({ promoCode, control, errors, onSubmit, loading }: FormProps) => {
  if (promoCode) {
    return (
      <div className="text-lg">
        Ваш промокод: <span className="font-medium">{promoCode}</span>
      </div>
    );
  }

  if (loading) {
    return <DotLoader style={{ transform: 'scale(2)' }} className="inline-block" />;
  }

  return (
    <form onSubmit={onSubmit} className="flex items-center sm:flex-col">
      <Controller
        control={control}
        name="phone"
        render={({ field: { onChange } }) => (
          <PhoneInputWithMask
            className={cn(
              errors.phone?.message ? 'border bg-[#FFECFC] text-black border-[#FF5757]' : '',
              'border border-transparent hover:border-orange-50 focus:border-orange-50 transition-all w-full max-w-[227px] h-12 px-4 py-3',
            )}
            errorMessageClassName="font-semibold text-[#FF5757] mt-1 sm:hidden"
            onChange={onChange}
            errorMessage={errors.phone?.message}
            placeholder="Номер телефона"
          />
        )}
      />
      <Controller
        control={control}
        name="email"
        render={({ field: { onChange, value } }) => (
          <div className="relative ml-4 sm:ml-0 sm:mt-2">
            <input
              type="text"
              placeholder="Email"
              onChange={onChange}
              value={value}
              className={cn(
                errors.email?.message ? 'border-[#FF5757] bg-[#FFECFC] text-black' : '',
                'w-full max-w-[227px] h-12 px-4 py-3 placeholder-[#666] rounded-[4px] border border-transparent hover:border-orange-50 focus:border-orange-50 transition-all',
              )}
            />
            {errors.email?.message && (
              <div className="absolute top-full mt-1 text-sm font-semibold text-[#FF5757] sm:hidden">
                {errors.email?.message}
              </div>
            )}
          </div>
        )}
      />

      <button
        type="submit"
        style={{ padding: 0, height: 48 }}
        className="bg-orange-50 text-white rounded-[4px] font-medium hover:bg-[#FA733A] transition-colors duration-200 ml-4 w-[200px] flex items-center justify-center !flex-shrink-0 sm:ml-0 sm:mt-4"
      >
        Забрать подарок
      </button>
    </form>
  );
};

export const HomeFirstOrder = () => {
  const { promoCode, dispatch } = useStoreon('promoCode');

  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: { phone: '', email: '' },
    resolver,
  });
  const { errors } = formState;

  const [res, mutation] = useHomeFirstOrderMutation();

  const onSubmit = handleSubmit(async (values) => {
    try {
      const updateRes = await mutation({ input: values });

      if (updateRes.error) {
        throw updateRes.error;
      }
      if (updateRes.data?.requestFirstOrderPromoCode.__typename === 'ErrorPayload') {
        throw new Error(updateRes.data.requestFirstOrderPromoCode.message);
      }
      if (updateRes.data?.requestFirstOrderPromoCode.__typename === 'RequestFirstOrderPromoCodePayload') {
        dispatch('setPromoCode', updateRes.data.requestFirstOrderPromoCode.promoCode);
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert(e.message);
    }
  });

  return (
    <div className="mb-4 mt-8 px-9 py-[60px] rounded-2xl bg-[#FAC03A] relative overflow-hidden md:mt-6 xs:pt-32 lg:mb-3">
      <div className="text-4xl mb-4 sm:text-2xl">
        <div className="font-semibold">Подарок</div>
        <div>с первым заказом</div>
      </div>
      <div className="mb-7 max-w-[590px] md:max-w-[450px] sm:text-sm sm:max-w-[380px]">
        Вы у нас впервые? У нас для вас подарок
      </div>
      <div className="mb-7 max-w-[590px] md:max-w-[450px] sm:text-sm sm:max-w-[380px]">
        Оставьте ваши контакты и получите скидку 300 рублей и бесплатную доставку наших вкусных продуктов.
      </div>

      <Form promoCode={promoCode} control={control} errors={errors} onSubmit={onSubmit} loading={res.fetching} />

      <img
        src={GiftImage}
        alt=""
        className="absolute -bottom-6 right-24 2xl:right-4 xl:w-[340px] xl:bottom-auto xl:top-2 xl:right-2 lg:w-[277px] md:-right-6 md:-top-10"
      />
    </div>
  );
};

gql`
  mutation HomeFirstOrderMutation($input: RequestFirstOrderPromoCodeInput!) {
    requestFirstOrderPromoCode(input: $input) {
      ... on ErrorPayload {
        message
      }
      ... on RequestFirstOrderPromoCodePayload {
        promoCode
      }
    }
  }
`;

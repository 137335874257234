import * as React from 'react';
import Slider, { Settings } from 'react-slick';
import cn from 'classnames';
import NextArrowIcon from 'components/icons/NextArrow';

import * as s from './CarouselContent.module.css';

type Props = {
  readonly slidesToShow: number;
  readonly dots?: boolean;
  readonly adaptiveHeight?: boolean;
  readonly styles?: string;
  readonly arrowIsCenter?: boolean;
  readonly centerMode?: boolean;
  readonly centerPadding?: string;
  readonly fullWidth?: boolean;
};

type ArrowProps = {
  readonly type: 'prev' | 'next';
  readonly onClick?: () => void;
  readonly styles: string | undefined;
  readonly arrowIsCenter?: boolean;
};

const arrowStyles =
  'w-[48px] h-[48px] absolute z-10 flex justify-center items-center bg-white cursor-pointer rounded-full duration-200 shadow-6xl hover:bg-[#FEDED0] active:shadow-none md:hidden';

const Arrow = ({ onClick, type, styles, arrowIsCenter }: ArrowProps) => {
  if (arrowIsCenter) {
    return (
      <button
        type="submit"
        className={cn(arrowStyles, styles, type === 'next' ? 'ml-[91%]' : 'ml-[-5%]')}
        onClick={onClick}
      >
        <NextArrowIcon className={type === 'prev' ? 'transform rotate-180' : undefined} />
      </button>
    );
  }

  return (
    <button
      type="submit"
      className={cn(arrowStyles, !styles ? 'top-[-82px] right-[10px]' : styles, type === 'prev' && 'mr-[72px]')}
      onClick={onClick}
    >
      <NextArrowIcon className={type === 'prev' ? 'transform rotate-180' : undefined} />
    </button>
  );
};

const ActiveDot = {
  backgroundColor: '#FA5813',
};

export const CarouselContent: React.FC<Props> = ({
  children,
  slidesToShow,
  dots = false,
  adaptiveHeight = true,
  styles,
  arrowIsCenter = false,
  centerMode = false,
  centerPadding,
  fullWidth = false,
}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const settings: Settings = {
    dots,
    infinite: true,
    adaptiveHeight,
    speed: 500,
    centerPadding,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToShow,
    slidesToScroll: 1,
    centerMode: fullWidth,
    nextArrow: <Arrow type="next" styles={styles} arrowIsCenter={arrowIsCenter} />,
    prevArrow: <Arrow type="prev" styles={styles} arrowIsCenter={arrowIsCenter} />,
    dotsClass: s.button__bar,
    beforeChange: (prev: number, next: number) => {
      setCurrentSlide(next);
    },
    customPaging: (i: number) => (
      <button type="submit" aria-label="dot" style={i === currentSlide ? ActiveDot : undefined}>
        {i + 1}
        {currentSlide}
      </button>
    ),
    className: s.block,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: slidesToShow > 1 ? 4 : 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: slidesToShow > 1 ? 4 : 1,
          centerMode,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShow > 1 ? 4 : 1,
          centerMode,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: slidesToShow > 1 ? 3 : 1,
          centerMode,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: slidesToShow > 1 ? 2 : 1,
          centerMode,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode,
        },
      },
    ],
  };

  return <Slider {...settings}>{children}</Slider>;
};

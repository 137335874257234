import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { gql } from '@urql/core';
import { useAttachImages } from 'hooks';
import { captureException } from 'utils';

import { Rating } from 'components/Rating';
import { ProgressButton } from 'components/ProgressButton';
import { AttachedImages } from 'components/AttachedImages';

import {
  OrderFeedback_Data,
  UploadImageType,
  useBasicOrderFeedbackRatingMutation,
  useBasicOrderFeedbackReviewMutation,
} from 'generated/graphql';

type Props = {
  readonly data: OrderFeedback_Data;
};

type OrderFeedbackState = {
  readonly text: string;
  readonly files: string;
};

export const OrderFeedback = ({ data }: Props) => {
  const [mutationStateReview, mutateReview] = useBasicOrderFeedbackReviewMutation();
  const [mutationStateRating, mutateRating] = useBasicOrderFeedbackRatingMutation();

  const inputFiles = React.useRef(null);
  const { images, setAttachedImages, dispatch } = useAttachImages(
    inputFiles,
    data.reviewImages.map((i) => ({ id: i.id, url: i.url })),
  );

  const [rating, setRating] = React.useState(data.rating || 5);
  const [showForm, setShowForm] = React.useState<'visible' | 'hide'>('hide');

  const orderId = data.id;
  const review = data.review || '';

  const { control, handleSubmit } = useForm<OrderFeedbackState>({
    defaultValues: {
      text: review,
    },
  });

  const onSubmit = handleSubmit(async (form) => {
    try {
      await mutateRating({
        orderId,
        value: rating,
      });

      if (rating !== 5) {
        await mutateReview({
          orderId,
          value: form.text,
          imageIds: (images.length > 0 && images.map((i) => i.id)) || [],
        });
      }

      if (!mutationStateRating.fetching && !mutationStateReview.fetching) {
        setShowForm('hide');
      }
    } catch (err) {
      captureException(err);
    }
  });

  const showFileSelector = () => dispatch({ type: 'showSelector' });
  const removeImage = (id: number) => dispatch({ type: 'remove', id });
  const handleRating = async (value: number) => {
    if (value < 5) {
      setShowForm('visible');
    } else {
      await mutateRating({
        orderId,
        value,
      });
      setShowForm('hide');
    }
    setRating(value);
  };

  return (
    <div className="w-full max-w-[350px] sm:max-w-[305px]">
      {!data.rating && <p className="text-sm mb-2">Пожалуйста, оцените заказ</p>}
      <Rating rating={rating} className="w-[50%] justify-between" onClick={handleRating} />
      <form
        className={`mt-4 ease-in duration-300 overflow-hidden ${
          showForm === 'visible' ? 'max-h-[1000px]' : 'max-h-[0px]'
        }`}
        onSubmit={onSubmit}
      >
        <Controller
          control={control}
          name="text"
          render={({ field: { value, onChange } }) => (
            <>
              <p className="text-sm mb-2">Что было не так с заказом? Мы хотели бы это исправить.</p>

              <textarea
                className="bg-gray-400 flex text-sm text-black placeholder-black 
                w-full px-2 h-[50px] min-h-[50px] py-2 mt-2"
                placeholder="Напишите что не так..."
                onChange={(e) => onChange(e.target.value)}
                defaultValue={value}
              />

              <div className="flex flex-row mt-4">
                <button className="h-full bg-gray-400 px-3 py-[2px] mr-2" type="button" onClick={showFileSelector}>
                  <div className="text-3xl text-gray-300">+</div>
                </button>

                {images.length > 0 && <AttachedImages images={images} onRemove={removeImage} />}
              </div>
            </>
          )}
        />

        <Controller
          control={control}
          name="files"
          render={({ field: { value, onChange } }) => (
            <input
              ref={inputFiles}
              type="file"
              onChange={(e) => {
                onChange(e.target.value);

                if (e.target.files) {
                  setAttachedImages(e.target.files, UploadImageType.ForOrderReview);
                }
              }}
              defaultValue={value}
              className="hidden"
              accept=".jpg, .jpeg, .png"
              multiple
            />
          )}
        />

        <ProgressButton
          type="submit"
          isProgress={mutationStateReview.fetching || mutationStateRating.fetching}
          styles="btn btn-orange justify-center flex-col overflow-hidden mt-5 mb-6 max-h-[45px]"
        >
          <p className="text-sm">Отправить отзыв</p>
        </ProgressButton>
      </form>
    </div>
  );
};

gql`
  fragment OrderFeedback_data on Order {
    id
    rating
    review
    reviewImages {
      id
      url
    }
  }

  mutation OrderFeedbackRatingMutation($input: UpdateOrderRatingInput!) {
    result: updateOrderRating(input: $input) {
      __typename

      ... on ErrorPayload {
        message
      }

      ... on UpdateOrderReviewPayload {
        viewer {
          id
          user {
            id
            orders {
              ...OrderFeedback_data
            }
          }
        }
      }
    }
  }

  mutation OrderFeedbackReviewMutation($input: UpdateOrderReviewInput!) {
    result: updateOrderReview(input: $input) {
      __typename

      ... on ErrorPayload {
        message
      }

      ... on UpdateOrderReviewPayload {
        viewer {
          id
          user {
            id
            orders {
              ...OrderFeedback_data
            }
          }
        }
      }
    }
  }
`;

import * as React from 'react';
import cn from 'classnames';
import { graphql } from 'gatsby';
import { HomeBanners_Data } from 'generated/graphql';
import { Banner } from './Banner';
import { CarouselContent } from './CarouselContent';
import { ProductCard } from './ProductCard';

type Props = {
  data: HomeBanners_Data;
};

export const HomeBanners = ({ data }: Props) => (
  <div className="flex justify-between my-8 md:mb-6 sm:mt-6 sm:mb-4">
    <div
      className={cn('w-full', data.hitProducts.length && 'max-w-[1100px] w-[100%] 2xl:max-w-[850px] xl:max-w-[100%]')}
    >
      <CarouselContent
        adaptiveHeight={false}
        slidesToShow={1}
        centerMode
        centerPadding="15"
        fullWidth={!(data.hitProducts.length > 0)}
        styles="bottom-[32px] right-[10%]"
      >
        {data.banners.map((b) => (
          <Banner
            data={b}
            key={b.id}
            className={cn('mr-4', data.hitProducts.length && 'pb-[53%] 2xl:pb-[69%] xl:pb-[53%]')}
          />
        ))}
      </CarouselContent>
    </div>
    {data.hitProducts.length > 0 && (
      <div className="w-[350px] xl:hidden">
        <div className="relative">
          <div className="bg-[#FFE3B9] h-[132px] px-[38px] rounded-[12px] flex justify-between items-baseline absolute top-[0] w-full">
            <h1 className="py-8 text-2xl font-semibold tracking-wider lg:hidden">Товары дня</h1>
            <span>{}</span>
          </div>
          <CarouselContent slidesToShow={1} arrowIsCenter styles="bottom-[50%] mt-10">
            {data.hitProducts.map((i) => (
              <ProductCard
                data={i}
                key={i.id}
                currentCategoryId={data.homeCategories[0].id}
                className="w-[275px] mt-[85px] mx-auto"
              />
            ))}
          </CarouselContent>
        </div>
      </div>
    )}
  </div>
);

export const gql = graphql`
  fragment HomeBanners_data on Query {
    hitProducts {
      ...ProductCard_product
    }

    homeCategories {
      id
      name
      pageUrl
      iconUrl(size: 64)
      products {
        ...ProductCard_product
      }
    }

    banners(origin: "web") {
      ...Banners_data
    }
  }
`;

import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { Settings } from 'react-slick';
import { HomeCategoryList_Data } from 'generated/graphql';
import { HomeCarousel } from './HomeCarousel';
import { Image } from './Image';

type Props = {
  data: HomeCategoryList_Data;
};

const sliderSettings: Settings = {
  slidesToShow: 7,
  responsive: [
    {
      breakpoint: 1540,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

export const HomeCategoryList = ({ data }: Props) => (
  <div className="py-8 xs:hidden">
    <div className="flex -mx-3 lg:-mx-1.5 md:justify-between">
      <HomeCarousel
        settings={sliderSettings}
        homeTitle={{
          title: 'Категории товаров',
          link: { pathTo: '/catalog', title: 'Перейти в каталог' },
        }}
      >
        {data.menuCategories.slice(2).map((cat) => (
          <div key={cat.id} className="px-3 lg:px-2">
            <Link
              key={cat.id}
              to={cat.pageUrl}
              className="border block border-orange-50 rounded-xl py-11 px-2 hover:bg-[#fff2f2] transition-colors"
            >
              <div className="h-16 w-16 mx-auto mb-3">
                <Image src={cat.iconUrl} alt={cat.name} />
              </div>
              <div className="text-orange-50 font-medium text-center mx-auto whitespace-nowrap  overflow-hidden overflow-ellipsis">
                {cat.name}
              </div>
            </Link>
          </div>
        ))}
      </HomeCarousel>
    </div>
  </div>
);

export const query = graphql`
  fragment HomeCategoryList_data on Query {
    menuCategories {
      id
      name
      pageUrl
      iconUrl(size: 64)
    }
  }
`;

import * as React from 'react';
import { Link } from 'gatsby';
import { UpdatedAt } from './UpdatedAt';
import HomeFooterLogo from './icons/homeFooterLogo.svg';
import InstagremIcon from './icons/HomeFooterInstagram';
import VkIcon from './icons/HomeFooterVk';
// import TelegramIcon from './icons/HomeFooterTelegram';
import TwitterIcon from './icons/HomeFooterTwitter';
import AppStoreImage from './icons/appStoreWhite.svg';
import GooglePlayImage from './icons/googlePlayWhite.svg';

const socialIcons = [
  {
    icon: <InstagremIcon />,
    pathTo: 'https://www.instagram.com/familyfriend_moscow/',
  },
  {
    icon: <VkIcon />,
    pathTo: 'https://vk.com/familyfriend_moscow',
  },
  // {
  //   icon: <TelegramIcon />,
  //   pathTo: 'https://web.telegram.org/',
  // },
  {
    icon: <TwitterIcon />,
    pathTo: 'https://twitter.com/share?url=https://familyfriend.com&text=FamilyFriend',
  },
];

const links = [
  {
    title: 'Покупателям',
    data: [
      // {
      //   name: 'Доставка и оплата',
      //   pathTo: '/',
      // },
      // {
      //   name: 'Депозит',
      //   pathTo: '/',
      // },
      {
        name: 'О нас',
        pathTo: '/about',
      },
      // {
      //   name: 'Для бизнеса',
      //   pathTo: '/',
      // },
    ],
  },
  {
    title: 'О компании',
    data: [
      // {
      //   name: 'О Family Friend',
      //   pathTo: '/',
      // },
      // {
      //   name: 'Отзывы',
      //   pathTo: '/',
      // },
      {
        name: 'Курьерам',
        pathTo: '/delivery/form',
      },
      {
        name: 'Партнерам',
        pathTo: '/partners',
      },
    ],
  },
];

const contantLinks = [
  {
    name: (
      <div>
        <span className="whitespace-nowrap xs:block">+7 (495) 419-95-54</span> Ежедневно с{' '}
        <span className="whitespace-nowrap">7:00</span> до <span className="whitespace-nowrap">21:00</span>
      </div>
    ),
    pathTo: 'tel:+74954199554',
  },
  {
    name: 'Telegram',
    pathTo: 'https://telegram.me/ffriend_bot',
  },
  {
    name: 'Vkontakte',
    pathTo: 'https://vk.me/familyfriend_moscow',
  },
  // {
  //   name: 'Facebook Messenger',
  //   pathTo: '/',
  // },
];

export const HomeFooter = () => (
  <div className="bg-[#333] pt-10 pb-6 px-2 mt-[72px] xl:mt-14 sm:mt-12">
    <div className="container flex justify-between sm:flex-col sm:items-center">
      <div className="md:max-w-auto sm:mb-10">
        <Link to="/">
          <img src={HomeFooterLogo} alt="tes" className="mb-8 xs:mx-auto xs:mb-4" />
        </Link>
        <div className="flex -mx-2">
          {socialIcons.map(({ icon, pathTo }) => (
            <a
              key={pathTo}
              style={{ boxShadow: '0px 0px 1px rgba(26, 32, 36, 0.32), 0px 4px 8px rgba(91, 104, 113, 0.24)' }}
              className="mx-2 h-12 w-12 bg-white flex items-center justify-center rounded-full"
              href={pathTo}
              target="_blank"
              rel="noopener noreferrer"
            >
              {icon}
            </a>
          ))}
        </div>
      </div>

      <div className="flex justify-evenly flex-grow md:flex-wrap xs:flex-col xs:text-center">
        {links.map(({ title, data }) => (
          <div
            key={title}
            className="w-full max-w-56 flex flex-col xl:w-40 md:max-w-auto md:w-1/2 mb-14 xs:mb-7 xs:w-full xs:items-center px-2"
          >
            <p className="text-white font-semibold">{title}</p>
            {data.map(({ name, pathTo }) => (
              <Link
                key={name}
                className="text-[#a8a8a8] mt-2 xl:text-sm hover:text-gray-500 transition-colors"
                to={pathTo}
              >
                {name}
              </Link>
            ))}
          </div>
        ))}
        <div className="w-full max-w-56 flex flex-col xl:w-40 md:max-w-auto md:w-1/2 mb-14 xs:mb-7 xs:w-full xs:items-center px-2">
          <p className="text-white font-semibold">Контакты</p>
          {contantLinks.map(({ name, pathTo }) => (
            <a
              key={pathTo}
              className="text-[#a8a8a8] mt-2 xl:text-sm hover:text-gray-500 transition-colors"
              href={pathTo}
              rel="noopener noreferrer"
              target="_blank"
            >
              {name}
            </a>
          ))}
        </div>
        <div className="w-full max-w-56 flex flex-col items-start md:max-w-auto md:w-1/2 xs:w-full xs:items-center px-2 sm:items-center">
          <p className="text-white font-semibold">Скачай наше приложение</p>
          <a
            href="https://itunes.apple.com/ru/app/id1121591818"
            rel="noopener noreferrer"
            target="_blank"
            className="mt-2.5 opacity-80 hover:opacity-100 transition-opacity"
          >
            <img src={AppStoreImage} alt="apple store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.familyfriend.client"
            rel="noopener noreferrer"
            target="_blank"
            className="mt-2.5 opacity-80 hover:opacity-100 transition-opacity"
          >
            <img src={GooglePlayImage} alt="google play" />
          </a>
        </div>
      </div>
    </div>
    <div className="container flex items-center justify-between sm:flex-col">
      <div className="text-[#A8A8A8] sm:mt-4 w-[280px] 2xl:w-[235px] lg:w-auto">
        <div>© FamilyFriend, 2015 - {new Date().getFullYear()}</div>
        <UpdatedAt />
      </div>
    </div>
  </div>
);

import * as React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import NextArrowIcon from 'components/icons/NextArrow';

export type HomeTitleProps = {
  title: string;
  link?: {
    title: string | React.ReactNode;
    pathTo: string;
    hideLinkMobile?: boolean;
  };
  sliderBtns?: {
    prev: () => void;
    next: () => void;
  };
};

const Button = ({ back, onClick }: { back?: boolean; onClick: () => void }) => (
  <button
    type="button"
    onClick={onClick}
    style={{ boxShadow: '0px 0px 1px rgba(26, 32, 36, 0.32), 0px 1px 2px rgba(91, 104, 113, 0.32)' }}
    className="h-12 w-12 flex items-center justify-center rounded-full ml-6 hover:bg-[#FEDED0] transition-colors xs:hidden flex-shrink-0"
  >
    <NextArrowIcon className={back ? 'transform rotate-180' : ''} />
  </button>
);

export const HomeTitle = ({ title, link, sliderBtns }: HomeTitleProps) => (
  <div className="mb-8 font-medium flex items-center justify-between px-3 md:mb-6 sm:mb-4">
    <h2 className="text-4xl py-0.5 md:text-3xl xs:text-2xl">{title}</h2>
    <div className="flex items-center">
      {link && (
        <Link to={link.pathTo} className={cn(link.hideLinkMobile && 'xs:hidden', 'text-orange-50')}>
          {link.title}
        </Link>
      )}
      {sliderBtns && (
        <>
          <Button onClick={sliderBtns.prev} back />
          <Button onClick={sliderBtns.next} />
        </>
      )}
    </div>
  </div>
);

import * as React from 'react';
import { Link } from 'gatsby';
import HomeFastImage from 'images/home-fast-image.png';
import AppsImage from 'images/home-apps-image.png';
import AppStoreImage from 'components/icons/appStoreBlack.svg';
import GooglePlayImage from 'components/icons/googlePlayBlack.svg';

const Banners = [
  {
    bg: '#B9E6FF',
    title: (
      <>
        <div>Используй</div>
        <div className="font-semibold">наше приложение</div>
      </>
    ),
    text: 'Скачивай наше приложение, которое экономит время и дарит скидки',
    actions: (
      <div className="flex xs:flex-col">
        <a
          href="https://apps.apple.com/ru/app/id1121591818"
          target="_blank"
          rel="noreferrer"
          className="hover:opacity-80 transition-opacity"
        >
          <img src={AppStoreImage} alt="app store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.familyfriend.client"
          target="_blank"
          rel="noreferrer"
          className="ml-2.5 xs:ml-0 xs:mt-2 hover:opacity-80 transition-opacity"
        >
          <img src={GooglePlayImage} alt="google play" />
        </a>
      </div>
    ),
    image: (
      <img
        className="absolute bottom-0 right-3 xl:-right-12 xl:opacity-[.99] lg:right-3 sm:-right-12 sm:-bottom-14 xs:max-w-[50%]"
        src={AppsImage}
        alt=""
      />
    ),
  },
  {
    bg: '#F7D6FF',
    title: (
      <>
        <div className="font-semibold">Быстрая доставка</div>
        <div>наше преимущество</div>
      </>
    ),
    text: 'Бережно доставим ваш заказ в течении 2-х часов',
    actions: (
      <Link to="/" className="text-orange-50 font-medium hover:text-[#FA733A] transition-colors">
        Узнать подробнее
      </Link>
    ),
    image: (
      <img
        className="absolute bottom-0 right-3 2xl:-right-16 xl:-right-36 xl:opacity-[.99] lg:right-3 sm:-right-12 sm:-bottom-14 xs:max-w-[90%]"
        src={HomeFastImage}
        alt=""
      />
    ),
  },
];

export const HomeDoubleBanner = () => (
  <div className="flex mt-4 pb-8 lg:flex-col -mx-2.5 lg:mx-0 lg:mt-3 sm:pb-6">
    {Banners.map((i) => (
      <div
        key={i.bg}
        style={{ backgroundColor: i.bg }}
        className={` rounded-2xl h-[352px] flex flex-col justify-center px-8 text-black relative overflow-hidden sm:px-4 w-1/2 mx-2 lg:w-full lg:mx-0 lg:mb-3`}
      >
        <div className="xl:w-[266px] lg:w-full">
          <div className="text-4xl mb-4 sm:text-2xl">{i.title}</div>
          <div className="mb-7 max-w-[300px] sm:text-sm sm:max-w-[400px] xs:max-w-[300px] sm:pr-[70px]">{i.text}</div>
          {i.actions}
        </div>
        {i.image}
      </div>
    ))}
  </div>
);
